
















































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/components/PageHeader.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import { CartItem, Order } from "@/types";
import { AuthData, UserProfile } from "@/store/auth/types";
import axios from "axios";

@Component<ShoppingCart>({
  metaInfo() {
    return {
      title: this.pageTitle
    };
  },
  components: {
    PageHeader,
    Breadcrumbs
  }
})
export default class ShoppingCart extends Vue {
  get breadcrumbs(): unknown[] {
    return [
      {
        text: "Ana Sayfa",
        exact: true,
        to: { name: "Home" }
      },
      {
        text: this.pageTitle
      }
    ];
  }

  get pageTitle(): string {
    return "Sepetim";
  }

  get authData(): AuthData {
    return this.$store.getters["auth/data"];
  }

  get isUserLoggedIn(): boolean {
    return this.$store.getters["auth/isUserLoggedIn"];
  }

  user = {} as UserProfile;

  get cartItemList(): CartItem[] {
    return this.$store.getters["cart/cartItemList"];
  }

  get cartItemCount(): number {
    return this.$store.getters["cart/cartItemCount"];
  }

  get cartPrice(): number {
    return this.$store.getters["cart/cartPrice"];
  }

  async openCartItem(item: CartItem): Promise<void> {
    const courseId = item.courseId;
    const authHeader = this.$store.getters["auth/requestAuthHeader"];

    // Get course
    var res = await axios.get(`/api/course/${courseId}`, {
      headers: authHeader
    });

    const courseUrlCode = res.data.urlCode;

    if (courseUrlCode) {
      this.$router.push({
        name: "CourseCatalogDetail",
        params: { urlCode: courseUrlCode }
      });
    }
  }

  removeCartItem(item: CartItem): void {
    this.$store.dispatch("cart/removeItem", item);
    this.$store.dispatch("cart/saveCart");
  }

  get nameRules(): unknown {
    return [(v: string) => !!v || "Lütfen adınızı yazınız"];
  }

  get corpNameRules(): unknown {
    return [(v: string) => !!v || "Lütfen kurum adınızı yazınız"];
  }

  get tcknRules(): unknown {
    return [
      (v: string) => !!v || "Lütfen TC kimlik numaranızı yazınız",
      (v: string) => /^[1-9]{1}[0-9]{9}[02468]{1}$/.test(v) || "Geçersiz TC kimlik numarası"
    ];
  }

  get taxNumberRules(): unknown {
    return [
      (v: string) => !!v || "Lütfen vergi numaranızı yazınız",
      (v: string) => /^[0-9]{10}$/.test(v) || "Geçersiz vergi numarası"
    ];
  }

  get emailRules(): unknown {
    return [
      (v: string) => !!v || "Lütfen e-postanızı yazınız",
      (v: string) => /.+@.+\..+/.test(v) || "Geçersiz e-posta adresi"
    ];
  }

  get phoneRules(): unknown {
    return [
      (v: string) => !!v || "Lütfen telefon numaranızı yazınız",
      (v: string) =>
        // Tr telefon 0532 111 11 11 ya da 05321111111
        /^(05)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/.test(v)
    ];
  }

  get addressRules(): unknown {
    return [(v: string) => !!v || "Lütfen açık adresinizi yazınız"];
  }

  get ccNumberRules(): unknown {
    return [
      (v: string) => !!v || "Lütfen kart numaranızı yazınız",
      (v: string) => /^([0-9]{4})\s?([0-9]{4})\s?([0-9]{4})\s?([0-9]{4})$/.test(v) || "Geçersiz kart numarası"
    ];
  }

  get ccHolderRules(): unknown {
    return [(v: string) => !!v || "Lütfen kart sahibini yazınız"];
  }

  get ccDateRules(): unknown {
    return [
      (v: string) => !!v || "Lütfen kartınızın son kullanma tarihini yazınız",
      (v: string) => /^([0-9]{2})\/([0-9]{2})$/.test(v) || "Geçersiz tarih"
    ];
  }

  get ccCvcRules(): unknown {
    return [(v: string) => !!v || "Lütfen CVC kodunu yazınız", (v: string) => /^([0-9]{3})$/.test(v) || "Geçersiz CVC"];
  }

  get confirmRules(): unknown {
    return [this.paymentForm.confirm || "Lütfen mesafeli satış sözleşmesini onaylayınız."];
  }

  orderId = "";
  submitDisabled = false;
  transferDialog = false;

  paymentForm = {
    billType: "individual" as "individual" | "corporate",
    name: `${this.authData.firstName} ${this.authData.lastName}`,
    corpName: "",
    tckn: "",
    taxNumber: "",
    email: this.authData.email,
    phone: "",
    address: "",
    paymentType: "transfer" as "creditcard" | "transfer",
    ccNumber: "",
    ccHolder: "",
    ccDate: "",
    ccCvc: "",
    confirm: false
  };

  validate() {
    const form: any = this.$refs.form;
    return form.validate();
  }

  createCcMask() {
    const normalizedCcNumber = this.paymentForm.ccNumber.replace(/ /g, "");
    const last4Digit = normalizedCcNumber.substring(normalizedCcNumber.length - 4, normalizedCcNumber.length);

    return "************" + last4Digit;
  }

  async createOrder(): Promise<string> {
    const newOrder: Order = {
      userId: this.user.id,
      billType: this.paymentForm.billType,
      name: this.paymentForm.name ? this.paymentForm.name : null,
      tckn: this.paymentForm.tckn ? this.paymentForm.tckn : null,
      email: this.paymentForm.email,
      phone: this.paymentForm.phone,
      corpName: this.paymentForm.corpName ? this.paymentForm.corpName : null,
      taxNumber: this.paymentForm.taxNumber ? this.paymentForm.taxNumber : null,
      address: this.paymentForm.address,
      paymentType: this.paymentForm.paymentType,
      ccNumber: this.paymentForm.paymentType == "creditcard" ? this.createCcMask() : null,
      ccHolder: this.paymentForm.paymentType == "creditcard" ? this.paymentForm.ccHolder : null,
      items: this.cartItemList,
      amount: this.cartPrice,
      installmentCount: 1,
      status: null, // null | success | error
      statusMessage: this.paymentForm.paymentType == "transfer" ? "Para transferi bekleniyor..." : "",
      paymentDetails: null
    };

    return this.$store.dispatch("cart/createOrder", newOrder);
  }

  async takeTransfer() {
    this.submitDisabled = true;
    this.$notify({
      text: "Siparişiniz kaydediliyor. Lütfen bekleyin..."
    });

    // Sipariş oluştur
    this.orderId = await this.createOrder();

    // Sepeti sil
    this.$store.dispatch("cart/deleteCart");
    this.$store.dispatch("cart/clearCart");

    // Dialog göster
    this.transferDialog = true;

    // Mesajı temizle
    this.submitDisabled = false;
    this.$notify({
      clean: true
    });
  }

  async submit() {
    // Formu kontrol et
    if (!this.validate()) return;

    if (this.paymentForm.paymentType == "transfer") {
      this.takeTransfer();
    } else {
      // this.takePayment();
    }
  }

  async mounted() {
    this.user = await this.$store.dispatch("auth/getUser");
    this.paymentForm.name = `${this.user.firstName} ${this.user.lastName}`;
    this.paymentForm.tckn = this.user.tckn;
    this.paymentForm.email = this.user.email;
    this.paymentForm.phone = this.user.phone;
    if (this.user.address) this.paymentForm.address = this.user.address;
  }
}
